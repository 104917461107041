html{
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background: url(./images/bg.jpg) no-repeat; 
  background-size: cover; 
  background-position: bottom;
  background-color:#eeeeeeee;
  min-height: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.myContainer {
  margin: 0 auto;
  max-width: 1280px;
  width: 95%;  
}
@media only screen and (min-width: 601px) {
  .myContainer {
    width: 95%;
  }
}
@media only screen and (min-width: 993px) {
  .myContainer {
    width: 95%;
  }
}

.htl-brand-logo {
  position: static;
  color: #fff;
  display: inline-block;
  font-size: 2.1rem;
  padding-left: 10px;
}

.auth{
  margin-top: 80px !important;
}

form{
  padding: 20px;
}

form button, h5{
  margin-top: 20px 0;
}

input[type=text]:not(.browser-default):focus:not([readonly]),
input[type=email]:not(.browser-default):focus:not([readonly]),
input[type=password]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]){
  border-color: #ec407a;
  box-shadow: none;
}

.fl-path{
  width: 0px;
}

/* Text input */
.all-cap {
  text-transform: uppercase;
}

.bold{
  font-weight: bold;
}

/* Floating Action Button*/

.floting-bottom-left{
  bottom: 45px !important; 
  right: auto !important;
  margin-left: 15%;
}

@media only screen and (min-width: 601px) {
  .floting-bottom-left{
    bottom: 45px !important; 
    right: auto !important;
    margin-left: 8%;
  }
}
@media only screen and (min-width: 993px) {
  .floting-bottom-left{
    bottom: 45px !important; 
    right: auto !important;
    margin-left: 5%;
  }
}

.fab-bl{
  bottom: 45px !important; 
  right: auto !important;
  margin-left: -10%;
}

@media only screen and (min-width: 601px) {
  .fab-bl{
    bottom: 45px !important; 
    right: auto !important;
    margin-left: -5%;
  }
}
@media only screen and (min-width: 993px) {
  .fab-bl{
    bottom: 45px !important; 
    right: auto !important;
    margin-left: 0%;
  }
}

.fab-br{
  bottom: 45px !important; 
  left: auto !important;
  margin-left: -10%;
}

@media only screen and (min-width: 601px) {
  .fab-br{
    bottom: 45px !important; 
    left: auto !important;
    margin-left: -5%;
  }
}
@media only screen and (min-width: 993px) {
  .fab-br{
    bottom: 45px !important; 
    left: auto !important;
    margin-left: 0%;
  }
}
.floting-bottom-right{
  bottom: 45px !important; 
}
/* Navbar */
.navbar-fixed {
  z-index: 999 !important; 
}

/* Table */

.table-overflow {
  overflow-x: auto !important;
}

.icon-head{
  width: 7%;
}

.collapse-head{
  width: auto;
}

.td0 {
  min-width: 100px !important;
  white-space:normal;
}

.td1 {
  min-width: 150px !important;
  white-space:normal;
}

.td2 {
  min-width: 200px !important;
  white-space:normal;
}

.td3 {
  min-width: 300px !important;
  white-space:normal;
}

.scroll {
  border: 0;
  border-collapse: collapse;
}    
.scroll tr {
  display: flex;
}    
.scroll td {
  flex: 1;
}    
.scroll thead tr:after {
  overflow-y: scroll;
  visibility: hidden;
  height: 0;
}    
.scroll thead th {
  flex: 1;
  display: block;
}    
.scroll tbody {
  display: block;
  overflow-y: auto;
  height: calc(80vh - 100px);
}

.table .right{
  float: none !important;
}

/* Chart Card */
.animate.fadeLeft {
  -webkit-animation: fadeLeft;
  animation: fadeLeft;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.justify-content-between {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.display-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.fit{
  width: 100% !important;
}

.fit:focus{
  outline: none !important;
}

.b0{
  border: 0px !important;  
}

.b1{
  border: 1px solid #cecece;  
  height: 30px;
  padding-top: 2px !important;
}

.mt-7{
  margin-top: 35px !important;
}

.mt-6{
  margin-top: 30px !important;
}

.mt-5{
  margin-top: 25px !important;
}

.mt-4{
  margin-top: 20px !important;
}

.mt-3{
  margin-top: 15px !important;
}

.mt-2{
  margin-top: 10px !important;
}

.mb-0 {
  margin-bottom: 0!important;
}

.mb-1{
  margin-bottom: 5px !important;
}

.mb-2{
  margin-bottom: 10px !important;
}

.mb-3{
  margin-bottom: 15px !important;
}

.mb-4{
  margin-bottom: 20px !important;
}

.mb-5{
  margin-bottom: 25px !important;
}

.mt-0 {
  margin-top: 0!important;
}

.ml-4{
  padding-left: 20px !important;
}
.p-0{
  padding: 0 !important;
}

.p-5{
  padding: 25px;
}

.pb-3{
  padding-bottom: 15px;
}

.pl-1{
  padding-left: 5px;
}

.pl-2{
  padding-left: 10px;
}

.pl-3{
  padding-left: 15px;
}

.pl-4{
  padding-left: 20px !important;
}

.pl-5{
  padding-left: 25px !important;
}

.pr-1{
  padding-right: 5px !important;
}

.pt-2{
  padding-top: 10px !important;
}

.pt-3{
  padding-top: 15px !important;
}

.pt-4{
  padding-top: 20px !important;
}

.pt-5{
  padding-top: 25px !important;
}

.ht-1 {
  height: 1.5rem !important;
}

.blank {
  margin-bottom: 50px;
}

h6 {
  font-size: 1.15rem;
  margin: .575rem 0 .46rem;
}

.sidenav .block{
  color: rgba(0,0,0,0.87);
  display: block !important;
  font-size: 14px;
  font-weight: 500;
  height: 48px !important;
  line-height: 48px !important;
  padding: 0 32px !important;
}

.sidenav li>span>i, .sidenav li>span>[class^="mdi-"], .sidenav li>span li>span>[class*="mdi-"], .sidenav li>span>i.material-icons {
  float: left;
  height: 48px;
  line-height: 48px;
  margin: 0 32px 0 0;
  width: 24px;
  color: rgba(0,0,0,0.54);
}

.medium-small {
  font-size: .9rem!important;
}

.float-right {
  float: right!important;
}

.card-stats-title {
  font-size: 1rem;
}

.card-stats-title i {
  font-size: 1.2rem;
  position: relative;
  top: 3px;
}

.chart-title {
  font-family: 'Fira Sans',sans-serif!important;
  font-size: 1.6rem;
  font-weight: 300;
}

p.collections-title {
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

p.collections-content {
  font-size: .9rem;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.task-cat, .task-cat {
  font-size: .8rem;
  padding: 2px 4px;
  border-radius: 2px;
  background-clip: padding-box;
  font-weight: 300;
  color: #fff;
}

/* Tootl tip*/
.material-tooltip {
  border-radius: 4px !important;
  min-height: 10px !important;
  line-height: 60% !important;
  max-width: calc(100% - 4px) !important;
}

/* Material Icons*/
.contact .material-icons{
  font-size: 15px !important;
  padding-right: 7px !important;
  font-style: italic;
}

.list i.material-icons th, td{
  width: 40px;
  height: 60px;
}

.list i.material-icons {
  display: none;
}

.doc-list i.material-icons {
  display: none;
}

tr:hover i.material-icons {
  display: inline-block;
}

.card-ed-del i.material-icons {
  display: none;
}

a:hover i.material-icons {
  display: inline-block;
}

.list-wrap:hover i.material-icons {
  display: inline-block;
}

.collection-ed-del .btn-flat{
  font-size: 20px !important;
  padding: 0 5px !important;
  font-style: italic;
}

.collection-ed-del i.material-icons{
  display: none;
}

li:hover i.material-icons{
  display: inline-block;
}

.card.todo i.material-icons {
  display: none;
}

.card.todo:hover i.material-icons {
  display: inline-block;
  margin-right: 10px;
}

.sel-comp i.material-icons {
  display: none;
}

.sel-comp:hover i.material-icons {
  display: inline-block;
  padding-left: 30px;
  font-size: 1.2rem;
}

.btn-edit{
  padding: 0 !important;
  text-align: right !important;
}


/* Section */
.sec-head{
  padding-top: 10px !important;
  padding-left: 20px !important;
}

.sec-head row{
  margin-bottom: 0 !important;
}

.sec-head col{
  padding: 2px !important;  
}

.sec-details{
  margin: 0 40px;
}

.css-g1d714-ValueContainer .search{
  height: 20px !important;
}

/* Container */

.btn-close {
  text-align: right;
}

.btn-gr {
  text-align: right;
}
.btn-gr .btn{
  padding-right:15px !important;
}

.btn-close-mr0 {
  margin-right: 0 !important;
  padding: 0 0 0 10px !important;
}


/* Card */

.card-conent-pt1{
  padding: 20px, 10px, 20px !important;
  border-radius: 0 0 2px 2px;
}

.card-ed-del{
  position: relative;
  top: 0px;
  text-align: right;
  padding: 0;
  height: 0;
}

.card-ed-del .btn-flat{
  padding: 0 !important; 
}

.card-ed-del a{
  margin-left: 5px;  
}

.card-ed-del i{
  font-size: 25px !important; 
}

.card .action-li{
  font-size: 12px !important; 
}

.card-bottom-badge ul{
  margin-bottom: 35px !important;
}

.card-bottom-badge .badge{
  float: left !important;
  margin: 0 5px 0 0 !important;
}

.card-title .sub{
  font-size: 14px !important;
  font-weight: bold !important;
}
/* Organization Component */

.container.org-unit{
  margin-top: 40px;
}

.section.org-unit {
  margin: 0px !important;
  padding: 0 !important;
}

.org-unit .card{
  margin: 0 !important;
}

.org-unit .content-wrap{
  min-height: 100vh;
}

.org-unit .row{
  margin: 0 !important;
}

.org-unit .col{
  padding: 0 !important;
}

.org-unit .btn{
  margin-right: 10px;
}

.org-unit thead {
  width: calc( 100% - 1em )
}

.org-unit table {
  width:100%;
} 
.org-unit th
{
  padding: 7px;
}

.org-unit td
{
  padding: 7px;
}

.org-unit.list i.material-icons th, td{
  width: auto;
  height: 45px !important;
}

.org-unit .card-content{
  min-height: 100vh;
}

.container.org-unit  .input-field{
  padding-right: 20px !important;
}

.org-unit textarea.materialize-textarea{
  height: 6rem;
}

/* Division Component */
.section.division {
  margin: 0px !important;
  padding: 0 !important;
}

.container.division {
  left: auto;
  right: auto;
  margin-top: 20px;
}

.container.division .col{
  padding-right: 10px !important;
}

.division .card{
  margin: 0 ;
}

.division .row{
  margin: 0 !important;
}

.division .col{
  padding: 0 !important;
}

.division-summary {
  margin: 5px !important;
}

.division .section {
  height: 100vh;
}

.division .btn{
  margin-right: 10px;
}

.division .div-sel{
  margin: 10px 0 30px 0;
}

/* User Component */
.section.user {
  margin: 0px !important;
  padding: 0 !important;
}

.user .card{
  margin: 0 ;
}

.user .section {
  min-height: 100vh;
  height: 100% !important;
}

.user .row{
  margin: 0 !important;
}

.user .col{
  padding: 0 !important;
}

.user-summary {
  margin: 5px !important;
}

.container.user {
  left: auto;
  right: auto;
  margin-top: 20px;
}

.container.user .col{
  padding-right: 10px !important;
}

.user .btn{
  margin-right: 10px;
}

.users-list{
  padding: 10px 20px !important;
}

.users-list p{  
  margin: 5px 0 5px 0;
}

.collection-ed-del {
  margin-left: 25px;
}

/* Product component */
.container.product{
  margin-top: 40px;
}

.section.product {
  margin: 0px !important;
  padding: 0 !important;
}

.product .card{
  margin: 0 !important;
}

.product .content-wrap{
  min-height: 100vh;
}

.product .row{
  margin: 0 !important;
}

.product .col{
  padding: 0 !important;
}

.product .btn{
  margin-right: 10px;
}


.product thead {
  width: calc( 100% - 1em )
}
.product table {
  width:100%;
  border: 0;
} 
.product th
{
  padding: 7px;
}

.product td
{
  padding: 7px;
}

.product.list i.material-icons th, td{
  width: auto;
  height: 45px !important;
}

.product .table-overflow{
  min-height: 100vh;
}

.container.product  .input-field{
  padding-right: 20px !important;
}

/* Customer component */
.container.customer{
  margin-top: 40px;
}

.section.customer {
  margin: 0px !important;
  padding: 0 !important;
}

.customer .card{
  margin: 0 !important;
}

.customer .content-wrap{
  min-height: 100vh;
}

.customer .row{
  margin: 0 !important;
}

.customer .col{
  padding: 0 !important;
}

.customer .btn{
  margin-right: 10px;
}

.customer thead {
  width: calc( 100% - 1em )
}
.customer table {
  width:100%;
  border: 0;
} 
.customer th
{
  padding: 7px;
}

.customer td
{
  padding: 7px;
}

.customer.list i.material-icons th, td{
  width: auto;
  height: 45px !important;
}

.customer .table-overflow{
  min-height: 100vh;
}

.container.customer  .input-field{
  padding-right: 20px !important;
}

.customer textarea.materialize-textarea{
  height: 6rem;
}

/* IOU component */
.container.iou{
  margin-top: 40px;
}

.container.iou  .input-field{
  padding-right: 20px !important;
}

.section.iou {
  margin: 0px !important;
  padding: 0 !important;
}

.iou .sec-head{
  padding-top: 25px !important;
  padding-left: 40px !important;
}

.iou .sec-head .col{
  padding-right: 20px !important;
}

.iou .card{
  margin: 0 !important;
}

.iou .sec-head{
  min-height: 100vh;
}

.iou .row{
  margin: 0 !important;
}

.iou .col{
  padding: 0px;
}

.iou-sum .col{
  padding-right: 10px !important;
  margin-top: 3px !important;
}

.iou-sum .card-content .col{
  margin-bottom: 10px !important;
}

.iou .btn{
  margin-right: 10px;
}

.iou table {
  width:100%;
  border: 0;
} 

.iou .table-overflow{
  min-height: 100%;

}

.iou thead {
  width: calc( 100% - 1em )
}

.iou th
{
  padding: 7px;
}

.iou td
{
  padding: 7px;
}

.iou.list i.material-icons th, td{
  width: auto;
  height: 45px !important;
}

.iou .card .card-action a{
  margin-right: 0 !important;
}

.iou textarea.materialize-textarea{
  height: 6rem;
}

.iou .cus-det .card-content{
  height: auto !important;
}

.msg .msg-lst{
  margin-bottom: 5px;
}

.pdf-view{
  width: 100% !important;
  min-height: 500px !important;
}

.iou-status.row{
  margin: -10px 0 0 0px !important;
}

.iou-status span.badge.new{
  float: left !important;
  margin: 0 10px 0 2px !important;
}

.iou-status .material-icons{
  margin-top: -10px !important;
}

.iou-edit-tool.close {
  display: none;
}

.iou-edit-tool.open {  
  display: block;
}


/* Dashboard */
.section.dashboard {
  margin: 0px !important;
  padding: 10px !important;
  min-height: 100vh;
}


table, tr .todo .tbl-b1{
  border: 1px solid black;
}

.todo td{
  border: 1px solid black;
  padding: 1px !important;
  margin: 0 !important;
  height: 20px;
}

.todo .box{
  border: 1px solid #ccc!important;
  border-radius: 10px;
  padding: 5px;
  font-size: 13px;
}

.todo .box .row {
  padding: 0 !important;
  margin-bottom: 5px !important;
}

.todo .todo-footer{
  margin-top: 10px;
  font-size: 12px;
}

.nothingTodo .hide{
  display: none;
}

.nothingTodo .show{
  display: block;
}

/* IOU Approval Summary */
.card-content.msg{
  padding-bottom: 0 !important;
}

.card-content.msg .card-title{
  margin-top: 12px !important;
  margin-left: 12px !important;
}


.msg{
  padding-top: 0px !important;
}

.msg button{
  margin-right: 20px !important;
}

.msg .row{
  margin-bottom: 3px !important;
}

.msg .input-field{
  margin-bottom: 0 !important;
  margin-left: 10px;
}

.org-approval{
  padding-left: 65px;
}

.div-approval{
  padding-left: 7px;
}

/* Box Container*/
table, tr .box-container .tbl-b1 {
  border: 1px solid black;
}

.box-container td{
  border: 1px solid black;
  padding: 1px !important;
  margin: 0 !important;
  height: 20px;
}

.box-container .box{
  border: 1px solid #ccc!important;
  border-radius: 10px;
  padding: 5px;
  font-size: 13px;
}

.box-container .box .row {
  padding: 0 !important;
  margin-bottom: 5px !important;
}

.box-container .todo-footer{
  margin-top: 10px;
  font-size: 12px;
}

/* Product List */
.product-list {
  border: 1px solid #ccc!important;
  /* border-radius: 5px; */
  font-size: 13px;
  min-height: 150px;
  overflow-y: auto !important;
}

.product-list .row {
  /* border-bottom: 1px solid #ccc!important; */
  vertical-align: middle !important;
  margin-bottom: 5px !important;  
  padding: 0 5px  0 5px;  
  margin-right: 0 !important;  
  margin-left: 0 !important;
}

.product-list .row.bb{
  border-bottom: 1px solid #ccc!important;
  vertical-align: middle !important;
  margin-bottom: 5px !important;  
  padding: 0 5px  0 5px;  
  margin-right: 0 !important;  
  margin-left: 0 !important;
}

.product-list .col{  
  padding : 3px !important;
  margin-right: 0 !important;  
  margin-left: 0 !important;
}

.product-total {
  border: 1px solid #ccc!important;
  /* border-radius: 5px; */
  font-size: 13px;
}

.product-total .col{  
  vertical-align: middle !important;
}

/* PDF view - file upload */
.pdf-view{
  max-height: 450px !important;
  overflow-y: auto !important;
}

.section .file-upload-ctl{
  margin-top: 15px;
  padding: 0 !important;
}

.file-upload-ctl .row{
  margin-bottom: 0 !important;
}

/* Delivery */
.input-qty {
  height: 20px !important;
  text-align: right !important;
}

/* Notifications */
.notifications li{
  padding: 10px 0 0 10px;
  font-size: small;
}

/* Orders */

.container.ord{
  margin-top: 40px;
}

.container.ord  .input-field{
  padding-right: 20px !important;
}

.section.ord {
  margin: 0px !important;
  padding: 0 !important;
}

.ord .sec-head{
  padding-top: 25px !important;
  padding-left: 40px !important;
  min-height: 100vh;
}

.ord .sec-head .col{
  padding-right: 20px !important;
}

.ord .p-0{
  padding: 0 !important;
}
.ord .card{
  margin: 0 !important;
}

.ord .row{
  margin: 0 !important;
}

.ord .col{
  padding: 0px;
}

.ord-det{
  padding: 25px 10px;
}

.ord-det p{
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.ord-det .col{
  padding-right: 10px !important;
  margin-top: 3px !important;
}

.ord-det .card-content .col{
  margin-bottom: 10px !important;
}

.ord .btn{
  margin-right: 10px;
}

.ord table {
  width:100%;
  border: 0;
} 

.ord .table-overflow{
  min-height: 100%;

}

.ord thead {
  width: calc( 100% - 1em )
}

.ord th
{
  padding: 7px;
}

.ord td
{
  padding: 7px;
}

.ord.list i.material-icons th, td{
  width: auto;
  height: 45px !important;
}

.ord .card .card-action a{
  margin-right: 0 !important;
}

.ord textarea.materialize-textarea{
  height: 6rem;
}

.ord .cus-det .card-content{
  height: auto !important;
}

.ord-cr .input-field{
  margin-top: 0 !important;
}

.ord-status.row{
  margin: -10px 0 0 0px !important;
}

.ord-status span.badge.new {
  float: left !important;
  margin: 0 10px 0 2px !important;
}

/* RMA */

.container.rma{
  margin-top: 40px;
}

.container.rma  .input-field{
  padding-right: 20px !important;
}

.section.rma {
  margin: 0px !important;
  padding: 0 !important;
}

.rma .sec-head{
  padding-top: 25px !important;
  padding-left: 40px !important;
  min-height: 100vh;
}

.rma .sec-head .col{
  padding-right: 20px !important;
}

.rma .p-0{
  padding: 0 !important;
}
.rma .card{
  margin: 0 !important;
}

.rma .row{
  margin: 0 !important;
}

.rma .col{
  padding: 0;
}

.rma-details .col.s6{
  padding: 0.75rem !important;
}

.rma .input-field{
  margin: 3px 0;
}

.rma textarea{
  min-height: 100px;
}

.rma-det{
  padding: 25px 10px;
}

.rma-det p{
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.rma-det .col{
  padding-right: 10px !important;
  margin-top: 3px !important;
}

.rma-det .card-content .col{
  margin-bottom: 10px !important;
}

.rma .btn{
  margin-right: 10px;
}

.rma table {
  width:100%;
  border: 0;
} 

.rma .table-overflow{
  min-height: 100%;

}

.rma thead {
  width: calc( 100% - 1em )
}

.rma th
{
  padding: 7px;
}

.rma td
{
  padding: 7px;
}

.rma.list i.material-icons th, td{
  width: auto;
  height: 45px !important;
}

.rma .card .card-action a{
  margin-right: 0 !important;
}

.rma textarea.materialize-textarea{
  height: 6rem;
}

.rma .cus-det .card-content{
  height: auto !important;
}

.rma-cr .input-field{
  margin-top: 0 !important;
}

.rma-status.row{
  margin: -10px 0 0 0px !important;
}

.rma-status span.badge.new {
  float: left !important;
  margin: 0 10px 0 2px !important;
}

.rma .cus-det input[type= text]{
  height: 1em;
}

.rma .txt-area-l{
  padding-left: 10px;
  margin-right: 20px;
}

.rma .txt-area-r{
  padding-left: 10px;
  margin-right: 20px;
  border-color: #cecede;
}

.rma .s-charge{
  width: 120px !important;
}

.used-comp {
  border: 1px solid #cecece;
  padding: 5px;
}

.input-field .col{
  padding-right: 20px !important;
}

.top-1{
  z-index: 100;
}

.comp tbody{
  overflow: scroll;
}

/* Filter */

.dropdown-menu {
  font-family: 'Fira Sans',sans-serif !important;
  width: auto;
}
.dropdown-content{
  width: auto !important;
}

.dropdown-content li{
  padding: 5px !important;
}


 
/* Modal - Keep this at bottom*/
.popup-wrapper {
  width: 100%;
  padding: 20px 5%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  border: 1px #cfcece dashed;
}

#message {
  height: auto;
}

.message {
  position: fixed;
  left: 0;
  right: 0;
  background-color: #fafafa;
  padding: 0;
  max-height: 100%;
  width: 60%;
  margin: auto;
  border-radius: 2px;
  will-change: top, opacity;
  padding: 16px;
}

.message.big {
  position: fixed;
  left: 0;
  right: 0;
  background-color: #fafafa;
  padding: 0;
  max-height: 100%;
  width: 97%;
  border-radius: 2px;
  will-change: top, opacity;
  padding: 6px;  
  overflow-y: auto !important;
}

.message.close {
  display: none;
}

.message.open {  
  z-index: 1003;
  display: block;
  opacity: 1;
  top: 5%;
  transform: scaleX(1) scaleY(1);
}

.message-overlay{
  position: fixed;
  z-index: 999;
  top: -25%;
  left: 0;
  bottom: 0;
  right: 0;
  height: 125%;
  width: 100%;
  background: #000;
  display: none;
  will-change: opacity;
}

.message-overlay.open{  
  z-index: 1002;
  display: block;
  opacity: 0.5;
}

.message-overlay.close{  
  z-index: 999;
  display: none;
}

.message .card .card-content .card-title{
  padding-top: 0px !important;
}

.modal-content .card{
  margin-top: 28px !important;
}
